import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {SelectPlan} from "../components/SelectPlan";

const PlanPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title="Seleccionar Plan"
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/plan"
      />


      <SelectPlan inAllsLine={true} spaceTop={false} spaceBottom={true} link={'/pay'} />

    </Layout>
  )
}

export default PlanPage;
