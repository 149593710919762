import styled, { createGlobalStyle } from 'styled-components'
import {GlobalFonts, GlobalColors} from '../../styles/GlobalStyles';

export const StylesOurPlans = createGlobalStyle`
  .one-row{
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .one-up{
    @media(min-width: 300px) and (max-width: 1023px){
      top: 0;
    }
    @media(min-width: 1024px){
      top: -100px !important;
    }
  }
  #ourplans .rec-pagination{
    margin-top: -50px;
  }
  #ourplans .rec-slider-container{
    margin: 0 !important;
  }
`
export const ContOurPlans = styled.div`
  margin-top: 60px;
`
export const ContOurPlansCards = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: ${props => props.spaceTop ? '15px' : 0};
    margin-bottom: ${props => props.spaceBottom ? '25px' : 0};
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: ${props => props.spaceTop ? '15px' : 0};
    margin-bottom: ${props => props.spaceBottom ? '25px' : 0};
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: ${props => props.spaceTop ? '15px' : 0};
    margin-bottom: ${props => props.spaceBottom ? '30px' : 0};
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: ${props => props.spaceTop ? '20px' : 0};
    margin-bottom: ${props => props.spaceBottom ? '50px' : 0};
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: ${props => props.spaceTop ? '15px' : 0};
    margin-bottom: ${props => props.spaceBottom ? '25px' : 0};
  }
  @media(min-width: 1920px){
    margin-top: ${props => props.spaceTop ? '15px' : 0};
  }
`
export const ContCard = styled.div`
  position: relative;
  width: 31%;
  height: auto;
  //margin: 0 auto;
  margin: 30px 8px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1.5s ease;
  &:hover{
    transform : scale(1.1);
  }
  
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    margin: 0 0 80px 0 !important;
    padding: 0 20px !important;
  }
  @media(min-width: 1024px){
    //width: 31%;
    //width: 26%;
    //padding: 0 10px !important;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 31%;
    padding: 0 10px !important;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 30%;
    padding: 0 10px !important;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 30%;
    padding: 0 10px !important;
  }
  @media(min-width: 1920px){
    width: 24%;
    padding: 0 10px !important;
  }
`;
export const TitleSectionH1 = styled.h1`
  top: ${props => props.top};
  font: 42px ${GlobalFonts.fontBlack};
  color: ${props => props.color ? props.color : GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 42px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 42px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 42px;
  }
  @media(min-width: 1920px){
    font-size: 42px;
  }
`
export const ContNocuota = styled.div`

`;

export const TextOrange = styled.div`
  font: 20px ${GlobalFonts.fontBold};
  color: #FF8675;
  font-style: bold;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin: 0 auto;
  width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #FFE7E3;
  
  @media(min-width: 300px) and (max-width: 767px){
  }
  @media(min-width: 768px) and (max-width: 1023px){
  }
  @media(min-width: 1024px) and (max-width: 1215px){
  }
  @media(min-width: 1216px) and (max-width: 1407px){
  }
  @media(min-width: 1408px) and (max-width: 1919px){
  }
  @media(min-width: 1920px) {
  }
`

export const ContPlanDesc = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  font: 20px ${GlobalFonts.fontRegular};
  color: #4F4F4F;
  line-height: 22px;
`;
