/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
//import favicon from '../images/favicon.png';
const favicon = '../images/favicon.png';

function SEO({ title, description, works, lang, meta, bolImage, ogImage, twitterImage, canonicalUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData{
        site {
          siteMetadata {
            title
            siteUrl
            description
            linkFacebook
            linkInstagram
            twitterSite
            author
          }
        }
      }
    `
  )

  //console.log('site.siteMetadata => ', site.siteMetadata);

  const metaTitle       = site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaUrl         = site.siteMetadata.siteUrl;
  const metaTwitterSite = site.siteMetadata.twitterSite;

  ogImage      = bolImage === 1 ? metaUrl + '/images/metas/' + ogImage : ogImage;
  twitterImage = bolImage === 1 ? metaUrl + '/images/metas/' + twitterImage : twitterImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title}`}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
      script={[
        {type: 'text/javascript', src: 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js'}
      ]}
      meta={[
        {
          name: `icon`,
          href: favicon,
          type: 'image/png'
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: 'all',
        },
        {
          name: `lang`,
          content: 'es',
        },
        {
          property: `keywords`,
          content: works,
        },
        {
          property: `og:title`,
          content: `${title} | ${metaTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:local`,
          content: 'en_US',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${metaTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: metaUrl,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        {
          name: `twitter:site`,
          content: metaTwitterSite,
        },
        {
          name: `twitter:creator`,
          content: '@guzbarraf',
        },
      ].concat(meta)}
      >
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
